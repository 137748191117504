





































import Vue from 'vue';
import Component from 'vue-class-component';

const CommonIcon = () => import('common-modules/src/components/CommonIcon.vue');

@Component({
  components: {
    CommonIcon,
  },
})
export default class RunCourseLegend extends Vue {}
